export default {
    public: {
        copy_success: "Copied successfully",
        download_success: "Saved successfully",
        button: {
            text: "Confirm",
            jump: "Skip",
            text_ok: "Confirm",
            text_ok1: "OK",
            cancel: "Cancel",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
    },
};
