import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const lang = computed(() => {
      return store.state.lang;
    });
    const putiLoading = computed(() => store.state.putiLoading);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_Loading = _resolveComponent("Loading");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['app', 'app-' + lang.value])
      }, [_createVNode(_component_router_view), putiLoading.value ? (_openBlock(), _createBlock(_component_Loading, {
        key: 0
      })) : _createCommentVNode("", true)], 2);
    };
  }
};