const Download = () => import("@/views/Download");
const HowToPlay = () => import("@/views/HowToPlay");
const WhitePaper = () => import("@/views/WhitePaper");
const Roadmap = () => import("@/views/Roadmap");

const routes = [
    {
        path: "/",
        redirect: {
            name: "download",
        },
    },
    {
        path: "/download",
        name: "download",
        component: Download,
    },
    {
        path: "/how-to-play",
        name: "how-to-play",
        component: HowToPlay,
    },
    {
        path: "/white-paper",
        name: "white-paper",
        component: WhitePaper,
    },
    {
        path: "/roadmap",
        name: "roadmap",
        component: Roadmap,
    },
];
export default routes;
